<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 offset-lg-4">
					<div class="login__lang header__lang">
						<HeaderLang></HeaderLang>
					</div>

					<div class="header__logo login__logo">
						<div class="header__logo--left">
							<a :href="'/' + $i18n.locale + '/'">
								<img src="../assets/img/logo.svg" alt="" />
							</a>
						</div>
						<div class="header__logo--right">
							<div class="header__logo--title">
								{{ $t('site.logo.title') }}
							</div>
							<div class="header__logo--subtitle">
								{{ $t('site.logo.desc') }}
							</div>
						</div>
					</div>

					<div class="login__block" v-if="success == 'success'">
						<div class="login__block--form">
							<div class="row">
								<div class="col-lg-12">
									<div class="login__block--tab login__block--active">
										<div class="recovery__block recovery__block--success">
											<div class="recovery__block--title">
												{{ $t('site.recovery.success.title') }}
											</div>
											<div class="recovery__block--link">
												<router-link :to="'/' + $i18n.locale + '/recovery'">{{
													$t('site.recovery.come_back')
												}}</router-link>
											</div>
										</div>
									</div>
								</div>

								<div class="col-lg-12">
									<div class="form__listitem--registr">
										<router-link :to="'/' + $i18n.locale + '/login'">{{
											$t('site.authorization.input_login')
										}}</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="login__block" v-else-if="success == 'error'">
						<div class="login__block--form">
							<div class="row">
								<div class="col-lg-12">
									<div class="login__block--tab login__block--active">
										<div class="recovery__block recovery__block--error">
											<div class="recovery__block--title">
												<!--<strong>IvanIvanov</strong><br>-->
												{{ $t('site.recovery.user_not_found') }}
											</div>
											<div class="recovery__block--link">
												<router-link :to="'/' + $i18n.locale + '/recovery'">{{
													$t('site.recovery.come_back')
												}}</router-link>
											</div>
										</div>
										<div class="recovery__block--line">
											<label class="form__block--input">
												<div class="form__block--title">
													{{ $t('site.recovery.contact_administrator') }}
												</div>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="login__block" v-else>
						<div class="content__title--block">
							<div class="content__title section__title">
								{{ $t('site.recovery.title') }}
							</div>
						</div>
						<div class="login__block--form">
							<div class="row">
								<div class="col-lg-12">
									<div class="login__block--tab login__block--active">
										<form method="POST" @submit.prevent="onSubmit">
											<div class="form__block--line">
												<label class="form__block--input">
													<input
														type="text"
														class="input-linck input-linck-icon"
														:class="[
															message.status != 200 && message.status != null ? 'input--error' : '',
														]"
														required=""
														name="iin_bin"
														value=""
														:placeholder="$t('site.recovery.form.iin_bin')"
													/>
													<div class="input__icon">
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.471 16.647v-1.5a3 3 0 00-3-3h-6a3 3 0 00-3 3v1.5M9.471 9.147a3 3 0 100-6 3 3 0 000 6z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</div>
												</label>
											</div>
											<div class="form__block--line">
												<label class="form__block--input">
													<input
														type="text"
														class="input-linck input-linck-icon"
														:class="[
															message.status != 200 && message.status != null ? 'input--error' : '',
														]"
														required=""
														name="email"
														value=""
														:placeholder="$t('site.recovery.form.email')"
													/>
													<div class="input__icon">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 16a4 4 0 100-8 4 4 0 000 8z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 8v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</div>
												</label>
											</div>

											<div class="form__block--line form__info--line">
												<label class="form__block--input">
													<span class="form__block--title">
														{{ $t('site.recovery.form.text') }}
													</span>
												</label>
											</div>

											<div
												class="form__block--line form__messages"
												v-if="message.text"
												:class="{
													'form__messages--error': message.status != 200,
													'form__messages--success': message.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ message.text }}
													</span>
												</label>
											</div>

											<div class="form__listitem--line form__listitem--button">
												<div class="form__listitem--input">
													<button class="input__button">
														{{ $t('site.recovery.send') }}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>

								<div class="col-lg-12">
									<div class="form__listitem--registr">
										<router-link :to="'/' + $i18n.locale + '/login'">{{
											$t('site.authorization.input_login')
										}}</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { api } from '@/boot/axios'
import HeaderLang from '@/components/HeaderLang'

export default {
	components: {
		HeaderLang,
	},
	data() {
		return {
			success: null,
			token: null,
			message: {
				status: null,
				text: null,
			},
			loading: null,

			password: '',
			inputType: false,
		}
	},
	beforeCreate() {
		if (localStorage.token) {
			delete localStorage.token

			location.reload()
		}
	},
	methods: {
		onSubmit(evt) {
			const formData = new FormData(evt.target)

			api
				.post('login/forgot', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = response.status
						this.message.text = response.data.message
					}
				})
				.catch((error) => {
					if (error?.response?.status == 401) {
						this.message.status = 401
						this.message.text = error.response.data.error_message
					}
					if (error?.response?.status == 422) {
						this.message.status = 422
						this.message.text = error.response.data.error_message
					}
				})
		},
	},
	head: {
		title() {
			return {
				inner: this.$t('site.recovery.title'),
			}
		},
		meta: function () {
			return [{ name: 'description', content: this.$t('site.recovery.description') }]
		},
	},
}
</script>

<style>
header .header__site--row {
	display: none;
}
</style>
